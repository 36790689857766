import "./style.scss"
import { useSelector, useDispatch } from "react-redux"
import { incrementByAmount } from "../../store/Header";
import { useEffect, useState } from "react";
import { HongKongMathsPageData } from "../../utils/data";
import { getPageListAPI, getBlogsListAPI } from "../../api";
import { useNavigate } from "react-router-dom";
import BlogCom from "../../components/BlogCom";
import EadlineCom from "../../components/EadlineCom";

const HongKongMathsPage = () => {
    const dispatch = useDispatch()
    const [isShow, setIsShow] = useState(false);
    const [pageData, setPageData] = useState();
    const [teacherInfoData, setTeacherInfoData] = useState([]);
    const [courseDataList, setCourseDataList] = useState([]);
    const [dataList1, setDataList1] = useState([]);
    const [hkTeacher, setHkTeacher] = useState([]);
    const [courseList, setCourseList] = useState();
    const [bannerPc, setBannerPc] = useState();
    const [bannerMob, setBannerMob] = useState();
    const [blogList, setBlogList] = useState(null)
    useEffect(() => {
        dispatch(incrementByAmount(3))
        getPageListAPI({ websiteType: '0', courseType: '1', area: '2' }).then((res) => {
            setPageData(res.data.companyIntroduce.introduce);
            setDataList1(res.data.teacherList);
            setCourseList(res.data.courseList);
            setBannerPc(res.data.website.photo);
            setBannerMob(res.data.website.photoMob);
            let arr = [];
            let arr1 = [];
            res.data.teacherList.map((item, index) => {
                if (item.type === '2') {
                    arr.push(item);
                } else {
                    arr1.push(item);
                }
            })
            setTeacherInfoData(arr);
            setHkTeacher(arr1);
        });

        setCourseDataList(HongKongMathsPageData.courseDataList);
        getBlogsListAPI({ area: '1', pageNum: 1, pageSize: 10 }).then((res) => {
            setBlogList(res);
        })
    }, [])
    const nav = useNavigate();
    const goMath = (url) => {
        nav(url);
    };
    const teacherInfoCom = (item, index) => {
        return (
            <div className="teacherItem" key={index}>
                <div className="teacherName">
                    <img className="photo" src={item.picture} alt="" />
                    <div className="name">{item.name}</div>
                </div>
                <div className="teacherInfo">
                    {item.introduce.split('/').map((item, index1) => {
                        return <div className="infoItem" key={index1}>
                            <div>
                                <div className="drop"></div>
                            </div>
                            <div>{item}</div>
                        </div>
                    })} </div></div>
        )

    }
    const classListCom = (item, index) => {
        return (<div className="classItem" key={index}>
            <img className="classIcon" src={courseDataList[index].url} alt="" />
            <div className="calssTitle">{item.name}</div>
            {item.content}
        </div>)
    }
    const advantagetCom = (item, index) => {
        return <div className="advantageItem" key={index}>
            <img src="../assets/maths/20.png" alt="" className="advantageIcon" />
            <div>{item.content}</div>
        </div>
    }
    const teacherItemCom = (item, index) => {
        return (<div className={["teacher_item", index === 1 || index === 4 ? 'tow' : '', index === 2 || index === 5 ? 'therr' : '',].join(" ")} key={index}>
            <img className="teachet_ph" src={item.picture} alt="" />
            <div>
                <div className="teacher_name">{item.name}</div>
                <div className="teacher_info">{item.introduce.replace(/\//g, '。')}</div></div>
        </div>)
    }
    const subheadingCom = (item, index) => {
        return (<div className={["grade_item", `grade_item${index + 1}`].join(" ")} key={index}>
            <div className="gradeName">{item.name}</div>
            <div className="gradeContent">{item.content}</div>
        </div>)
    };
    const goUrl = () => {
        let url = "https://wa.me/8617788052878?text=%E5%92%A8%E8%AF%A21V1%E8%AF%BE%E7%A8%8B";
        window.open(url);
    }
    return (
        <div className="hkmaths">
            {/* <VerticalScrollList items={blogList ? blogList : ""} /> */}
            <div className="topBut">
                <button className="but2" onClick={() => { goMath('/hongkong') }} >香港中文</button>
                <button className="but1" onClick={() => { goMath('/hongkongmaths') }}>香港数学</button>
                <button className="but2" onClick={() => { goMath('/hongkong-English') }} >香港英文</button>

            </div>
            <div className="mathsDiv">
                <div className="mathsHeader" onClick={() => { goUrl() }}>
                    <img src={bannerMob} alt="" />
                </div>

                <div className="mathsContent">
                    {/* <!-- 公司簡介 --> */}
                    <div className="aboutUs">
                        <div className="aboutTitle">公司簡介</div>
                        <div className="aboutUs_content">
                            {pageData}                </div>
                    </div>
                    <div className="aboutImg"><img src="../assets/maths/aboutus.png" alt="" /></div>
                    <div className="teacherModule">
                        <div className="teacherTitle">
                            <span className="big">名師介紹</span><span className="smile">-普教老師</span>
                            <div className="yellow_line"></div>
                        </div>
                        <div className="teacherContent">
                            {teacherInfoData?.map((item, index) => {
                                return teacherInfoCom(item, index)
                            })} </div>
                        <div className="aboutImg" style={{ right: "-80%" }}> <img src="../assets/maths/apple.png" alt="" /></div>
                    </div>
                    <div className="teacherModule">
                        <div className="teacherTitle">
                            <span className="big">名師介紹</span><span className="smile">-粵教老師</span>
                            <div className="yellow_line"></div>
                        </div>
                        <div className="teacherContent1">
                            {hkTeacher.map((item, index) => {
                                if (index == 1) {
                                    return <div className="teacherContent1_item" style={{ backgroundColor: "#FFD465" }} key={index}>
                                        <div className="right">
                                            <div className="rightTitle">寧鈞龍老師</div>
                                            <div className="rightText">
                                                畢業於中國海洋大學，從事教育行業十年，擁有多年帶高考提分經驗，善於引起學生興趣，深入淺出，所帶學生考取華南農業大學、廣東工業大學等知名學府，深受家長和學生信賴，纍計多帶中小學生3000+。
                                            </div>
                                        </div>
                                        <div className="left left2">
                                            <img src="../assets/maths/yt2.png" alt="" />
                                        </div>

                                    </div>
                                } else {
                                    return (<div className="teacherContent1_item" key={index}>
                                        <div className="left">
                                            <img src={item.pictureMob} alt="" />
                                        </div>
                                        <div className="right">
                                            <div className="rightTitle">{item.name}</div>
                                            <div className="rightText">
                                                {item.introduce}
                                            </div>
                                        </div>
                                    </div>)
                                }
                            })}
                            {/* <div className="teacherContent1_item">
                        <div className="left">
                            <img src="../assets/maths/yt1.png" alt="" />
                        </div>
                        <div className="right">
                            <div className="rightTitle">李東華老師</div>
                            <div className="rightText">
                                2018年畢業于暨南大學，掌握普通話、粵語、英語等語言。有四年以上的綫上、綫下教學工作經驗，擅長進行中學、小學的理科科目教學，善於發掘學生在數學理科等方面的學習思路。上課課堂可以做到生動有趣，激發缺省的學習激情，培養學生的學習習慣。教學理念：教育是一件需要努力。需要等待的事情，而學習是人一輩子的重要事。
                            </div>
                        </div>
                    </div>
                    <div className="teacherContent1_item" style={{ backgroundColor: "#FFD465" }}>
                        <div className="right">
                            <div className="rightTitle">寧鈞龍老師</div>
                            <div className="rightText">
                                畢業於中國海洋大學，從事教育行業十年，擁有多年帶高考提分經驗，善於引起學生興趣，深入淺出，所帶學生考取華南農業大學、廣東工業大學等知名學府，深受家長和學生信賴，纍計多帶中小學生3000+。
                            </div>
                        </div>
                        <div className="left left2">
                            <img src="../assets/maths/yt2.png" alt="" />
                        </div>

                    </div>
                    <div className="teacherContent1_item" style={{ backgroundColor: "#FF7431" }}>
                        <div className="left"> <img src="../assets/maths/yt3.png" alt="" /></div>
                        <div className="right">
                            <div className="rightTitle">李妍鈺老師</div>
                            <div className="rightText">
                                香港城市大學研究生，曾獲得廣州市教學技能比賽二等獎。 因材施教，關註每位學生的差異，通過個性化的教學輔導後進生，平均提分20分。
                            </div>
                        </div>
                    </div> */}
                        </div>
                        <EadlineCom name="博客" />
                        {
                            blogList != null ? <BlogCom data='1' blogLists={blogList} /> : null
                        }
                        <div className="courseInfo">
                            <div className="courseInfoTitle" >課程介紹</div>
                            <div className="courseContent">
                                {courseList?.map((item, index) => {
                                    if (index === 0) {
                                        return <div key={index}>
                                            <div className="courseTitle">
                                                {item.name}{/* 1.《現代小學數學》課程體系 */}</div>
                                            <div className="grade">
                                                <img src={item.photoMob} alt="" />
                                            </div>
                                        </div>
                                    } else if (index === 1) {
                                        return <div key={index}>
                                            <div className="courseTitle">{item.name}</div>
                                            <div className="classResearch">{item.content}</div>
                                            <div className="classList">
                                                {item.courseDetailList.map((item, index) => { return classListCom(item, index) })}
                                            </div>
                                        </div>
                                    } else if (index === 2) {
                                        return <div key={index}> <div className="courseTitle">
                                            {item.name}
                                            <div className="classBag">
                                                <img src={item.photoMob} alt="" />
                                            </div>
                                        </div></div>
                                    } else if (index === 3) {
                                        return <div key={index}>
                                            <div className="courseTitle" >
                                                {item.name}
                                            </div>
                                            <div className="advantage">
                                                {
                                                    item.courseDetailList.map((item, index) => {
                                                        return advantagetCom(item, index)
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mathsPc" >
                <div className="banner" onClick={() => { goUrl() }}>
                    <img src={bannerPc} alt="" />
                </div>
                <div className="companyProfile">
                    <div className="titleBox">
                        <img className="icon_left" src="../assets/Maths/Ellipse13.png" alt="" />
                        <div className="title"><span className="bule">公司</span>簡介</div>
                        <img className="icon_right" src="../assets/Maths/Ellipse14.png" alt="" />
                    </div>
                    <div className="underline"></div>
                    <div className="content">{pageData}</div>
                </div>
                {/* <!-- 老师介绍 --> */}
                <div className="teacher_introduced">
                    <div className="teacher_title">名師介紹</div>
                    <div className="nav">
                        <div className={["teacherNav1", 'teacherNav', isShow ? '' : 'yellow'].join(" ")} onClick={() => { setIsShow(false) }}>普教老師
                            {isShow ? "" : <img src="../assets/Maths/subtract1.png" alt="" />}
                        </div>
                        <div className={["teacherNav2", 'teacherNav', isShow ? 'yellow' : ''].join(" ")} onClick={() => { setIsShow(true) }}>粵教老師
                            {isShow ? <img src="../assets/Maths/subtract1.png" alt="" /> : ""}</div>
                    </div>
                    <div className="teacher_box">
                        {
                            dataList1.map((item, index) => {

                                if (isShow === true) {
                                    if (item.type == '1') {
                                        return teacherItemCom(item, index);
                                    }
                                } else {
                                    if (item.type == '2') {
                                        return teacherItemCom(item, index);
                                    }
                                }
                            })
                        }
                    </div>
                </div>
                <EadlineCom name="博客" />
                {
                    blogList != null ? <BlogCom data='1' blogLists={blogList} /> : null
                }
                {/* <!-- 公司介绍 --> */}
                <div className="company_info">
                    <div className="titleBox">
                        <img className="icon_left" src="../assets/Maths/Ellipse13.png" alt="" />
                        <div className="title"><span className="bule">課程</span>簡介</div>
                        <img className="icon_right" src="../assets/Maths/Ellipse14.png" alt="" />
                    </div>
                    <div className="underline"></div>
                    {courseList?.map((item, index) => {
                        if (index === 0) {
                            return <div key={index}>
                                <div className="subheading">{item.name}</div>
                                <div className="tableImg">
                                    <img src={item.photo} alt="" />
                                </div>
                            </div>
                        } else if (index === 1) {
                            return <div key={index}>
                                <div className="subheading">{item.name}</div>
                                <div className="subheadingContent">{item.content}</div>
                                <div className="grade">
                                    {
                                        item.courseDetailList.map((item, index) => {
                                            return subheadingCom(item, index);
                                        })
                                    }
                                </div>
                            </div>
                        } else if (index === 2) {
                            return <div key={index}></div>
                        } else if (index === 3) {
                            return <div key={index}></div>
                        }
                    })}
                    <div className="subheading" style={{ textAlign: " left" }}>3.課程特色</div>
                </div>
                {courseList?.map((item, index) => {
                    if (index === 2) {
                        return <div className="cloudLeft" key={index}>
                            <div className="cloudLefImg">
                                <img src="../assets/Maths/Business1.png" alt="" />
                            </div>
                            <div className="round">
                                <img src={item.photo} alt="" />
                            </div>
                        </div>
                    } else if (index === 3) {
                        return <div key={index}> <div className="subheading">{item.name}</div>
                            <div className="cloudRight">
                                <div className="cloudRightBox">
                                    <div className="cloudRight_img">
                                        <img src="../assets/Maths/Business2.png" alt="" />
                                    </div>
                                    <ul>
                                        {
                                            item.courseDetailList.map((item, index) => {
                                                return (<li key={index}>
                                                    <img src="../assets/Maths/selected.png" alt="" />
                                                    <div>{item.content}</div>
                                                </li>)
                                            })
                                        }
                                    </ul>
                                </div>
                            </div></div>
                    }
                })}
            </div></div>
    )
}
export default HongKongMathsPage;