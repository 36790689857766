import "./style.scss";
import { useDispatch } from "react-redux"
import { incrementByAmount } from "../../store/Header";
import { useEffect } from "react";
import { Button, Form, Input, message } from 'antd';
import { useState } from "react";
import { getContactList, subFrom } from "../../api";
const AboutUsPage = () => {
    const dispatch = useDispatch();
    const [chatTypeList, setChatTypeList] = useState([]);
    const [banner, setBanner] = useState([]);
    const [email, setEmail] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    useEffect(() => {
        dispatch(incrementByAmount(6));
        getContactList().then((res) => {
            setChatTypeList(res.data.companyList);
            setBanner([{ photo: res.data.photo }, { photoMob: res.data.photoMob }]);
            setEmail([res.data.contactMail, res.data.applyContactMail]);
        })
    }, []);
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };
    /* eslint-enable no-template-curly-in-string */
    const success = (data) => {
        messageApi.open({
            type: 'success',
            content: data,
            className: 'custom-class',
            style: {
                marginTop: '20vh',
            },
        });
    };
    const onFinish = (values) => {
        subFrom(values).then((res) => {
            success(res.msg);
            form.resetFields()
        })
    };
    const chatTypeItemCom = (item, index) => {
        return (
            <div className="chatTypeItem" key={index}>

                <div className="typeName">
                    {item.companyName}
                </div>
                <div className="line"></div>
                <div className="typeAddess">
                    <img src="../assets/ChatUs/address.png" alt="" />{item.companyAddress}
                </div>
                <div className="typePhone">
                    <img src="../assets/ChatUs/phone.png" alt="" />{item.companyPhone}
                </div>
            </div>
        )
    }
    return (
        <div className="aboutUs">
            {contextHolder}
            <div className="banner">
                <img className="bannerPc" src={banner[0]?.photo} alt="" />
                <img className="bannerMob" src={banner[1]?.photoMob} alt="" />
                <div className="bannerContent">
                    <div className="chatCh">联系我们</div>
                    <div className="chatEn">CONTACT US</div>
                    <div className="mail">联系我们邮箱：<a href={`mailto:${email[0]}`}>{email[0]}</a></div>
                    <div className="mail">联系我们邮箱：<a href={`mailto:${email[1]}`}>{email[1]}</a></div>
                </div>
            </div>
            <div className="chatType">
                {chatTypeList.map((item, index) => {
                    return chatTypeItemCom(item, index)
                })}
            </div>
            {/* <div className="chatInfo">
                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={onFinish}
                    style={{ maxWidth: 600 }}
                    validateMessages={validateMessages}
                    form={form}
                >
                    <Form.Item name={['name']} label="姓名/Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={['phono']} label="电话/Phone" rules={[{ type: 'phone', required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item className="email" name={['email']} label="邮箱/Email" rules={[{ type: 'email', required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item className="email" name={['specificNeed']} label="具体需求/Request">
                        <Input.TextArea rows={4} maxLength={6} />
                    </Form.Item>
                    <Form.Item />
                    <div>
                        <Button htmlType="reset" className="reset">重置</Button>
                        <Button htmlType="submit" style={{ background: '#ffd62c', }}>
                            确认
                        </Button>
                    </div>
                </Form>
            </div> */}
        </div >
    )
}
export default AboutUsPage;