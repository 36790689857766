import "./style.scss"
import { useDispatch } from "react-redux"
import { incrementByAmount } from "../../store/Header";
import { useEffect, useRef } from "react";
import { Carousel } from 'antd';
import { SoundOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import EadlineCom from "../../components/EadlineCom";
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPageListAPI, getBlogsListAPI } from "../../api/index";
import BlogCom from "../../components/BlogCom";
// import BlogCom from "../../components/BlogCom";
const HongKongEngPage = () => {
    const dispatch = useDispatch()
    const carouselRef = useRef(null);
    const [courseDataList, setCourseDataList] = useState([]);
    const [teacherDatalist, setTeacherDatalist] = useState([]);
    const [serviceContentList, setServiceContentList] = useState([]);
    const [website, setWebsite] = useState({});
    const [talkList, setTalkList] = useState([]);
    const [blogList, setBlogList] = useState(null)
    let colList = ["#C4EFDC", "#F9F3FF", "#E8EBCD"]
    const swiperRef = useRef(null);
    const nav = useNavigate();
    const goMath = (url) => {
        nav(url);
    };
    useEffect(() => {
        dispatch(incrementByAmount(4));
        getPageListAPI({ websiteType: '0', courseType: '0', area: '0' }).then((res) => {

            setServiceContentList(res.data.serviceList)

        });
        let TeacherDatalist = [
            {
                name: "唐老师",
                introduce: "本科畢業於西安外國語大學，研究生畢業於集美大學，擁有紮實的語言學與教育背景。曾任教於環球雅思國際英語，具備豐富的教學經驗，尤其擅長3-12歲學生的全英文授課。擅長透過生動的課堂互動和高效的學習策略激發學生興趣，培養英語聽讀寫綜合能力。在其指導下，學生成績平均維持在95分以上。",
                picture: "../assets/hkEngimg/tang.jpg"
            },
            {
                name: "高老师",
                introduce: "香港理工大學英語教育專業畢業，擁有多年的英語教學經驗，專注於培養學生的聽、說、讀、寫全方位能力。善於針對不同年齡和學習需求設計個性化教學方案，寓教於樂，讓學生在輕鬆愉快的氛圍中掌握英語。曾幫助眾多學生提高學業成績及英語口語水平，深受家長和學生的喜愛與好評。無論是希望提升應試能力，還是學習實用英語，该老師都能提供專業且高效的指導！",
                picture: "../assets/hkEngimg/gao.jpg"
            },
            {
                name: "Betti",
                introduce: "8年教培產業一線代課+教研經驗；6年教培業創業經驗；5年多的教輔圖書責編經驗 ，紮實系統的英語專業知識，能夠快速解決專業方面的問題 ，提高工作效率 。同時，豐富的教研經驗也讓其具備了較強的課程開發和教材編寫能力 ，能夠根據教學目標和學生特點 ，制定合理的教學計劃和教學方法。",
                picture: "../assets/hkEngimg/betti.jpg"
            }]
        setTeacherDatalist(TeacherDatalist);
        let talkList = ['../assets/hkEngimg/pl11.jpg', '../assets/hkEngimg/pl22.jpg', '../assets/hkEngimg/pl33.jpg', '../assets/hkEngimg/pl44.jpg', '../assets/hkEngimg/pl5.jpg']
        setTalkList(talkList);
        getBlogsListAPI({ area: '4', pageNum: 1, pageSize: 10 }).then((res) => {
            setBlogList(res);
        })
    }, []);
    const chatUsCom = () => {
        let url = "https://api.whatsapp.com/send/?phone=8617788052878&text=%E8%AB%AE%E8%A9%A2%E8%8B%B1%E6%96%87%E8%AA%B2%E7%A8%8B";
        return (
            <div className="chatUs" onClick={() => { window.open(url) }}>
                <div className=""><img src="../assets/HK/service.png" alt="" /> 點擊咨詢</div>
            </div>
        );
    }
    const teacherItemCom = (item, index) => {
        return (
            <div key={index} className={['teacherItem', index === 1 ? "higthBox" : ""].join(" ")}>

                <div className="teacherHead" style={{ backgroundColor: colList[index] }}><img src={item.picture} alt="" /></div>
                <div className="teacherContent">
                    <div className="teacherName">
                        {item.name}<span>{item.quality}</span>
                    </div>
                    <div className="teacherInfo">{item.introduce}</div>
                </div>
            </div>
        )
    }
    const serviceContentCom1 = (item, index) => {
        return (
            <div key={index} className={['serviceItem', index % 2 != 0 ? "service1" : ""].join(" ")}>
                <div className="left">
                    <div className="serviceTitle">{item.title}</div>
                    <div className="serviceInfo">{item.content}</div>
                </div>
                <div className="right"> <img src={item.picture} alt="" /></div>
            </div>
        )
    }
    return (
        <div className="hkPage">
            <div className="topBut">
                <button className="but2" onClick={() => { goMath('/hongkong') }} >香港中文</button>
                <button className="but2" onClick={() => { goMath('/hongkongmaths') }}>香港数学</button>
                <button className="but1" onClick={() => { goMath('/hongkong-English') }} >香港英文</button>
            </div>
            <div className="barrn">
                <img className="imgMob" src='../assets/hkEngimg/engbanner.jpg' alt="" />
                <img className="imgPc" src='../assets/hkEngimg/engbanner-pc.jpg' alt="" />
                <div className="nameTitle1">
                    <div>樂 學 英 文</div>
                    <div className="englishTitle">Enjoy Learning English</div>
                </div>

            </div>
            <EadlineCom name="課程簡介" />
            <div className="courseMol">
                <img src="../assets/hkEngimg/classeng.jpg" alt="" />
                {/* {
                    courseDataList.map((item, index) => {
                        return courseItemCom(item, index)
                    })
                } */}
            </div>
            {chatUsCom()}
            <EadlineCom name="教師資質" />
            <div className="teachers">
                {teacherDatalist.map((item, index) => {
                    return teacherItemCom(item, index)
                })}
            </div>
            <div className="teachers teacherMob">
                <Carousel effect="fade" autoplay autoplaySpeed={4000}>
                    {teacherDatalist.map((item, index) => {
                        return teacherItemCom(item, index)
                    })}
                </Carousel>
            </div>
            {chatUsCom()}
            <div className="teacherAptitude">
                <EadlineCom name="教師資質" />
                <div className="aptitudeImg">
                    <img className="aptitudePc" src="../assets/hkEngimg/tPC.png" alt="" />
                    <img className="aptitudeMob" src="../assets/hkEngimg/tM.png" alt="" />
                </div>
                {chatUsCom()}
            </div>
            <EadlineCom name="家長與老師互動" />
            <div className="service">
                <div className="arrow" onClick={() => { carouselRef.current.prev() }}><LeftOutlined /> </div>
                <div className="interact">
                    <div>
                        <Carousel effect="fade" autoplay dots={true} ref={carouselRef} autoplaySpeed={4000}>
                            {talkList.map((item, index) => (
                                <div className="swiperImg" key={index} >
                                    <img className="talkImg" src={item} alt="" />
                                </div>))}

                        </Carousel>
                    </div>
                </div>
                <div className="arrow" onClick={() => { carouselRef.current.next() }}>
                    <RightOutlined />
                </div>
            </div>
            {chatUsCom()}
            <EadlineCom name="博客" />
            {
                blogList != null ? <BlogCom data='4' blogLists={blogList} /> : null
            }
            <EadlineCom name="服務内容" />
            <div className="serviceContent">
                {serviceContentList.map((item, index) => {
                    return serviceContentCom1(item, index)
                })}
            </div>
            {chatUsCom()}
            <div style={{ marginBottom: "30px" }}></div>
        </div >
    )
}
export default HongKongEngPage;