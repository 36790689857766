import './style.scss'
import { useNavigate } from "react-router-dom"
const Privacy = () => {
    const nav = useNavigate();
    const go = () => {
        nav('/')
    }
    return <div>
        <div class="content-text">
            <button class="back" onClick={() => { go() }}>返回</button>
            <h3>隐私政策</h3>
            <p>我们保护您的个人信息</p>
            <p>概述</p>
            <p>
                1.
                Sino-Bus尊重您的个人信息隐私，因此我们制定了本隐私政策（我们的“隐私政策”），旨在解释我们如何收集、使用、分享和保护您的个人信息（这是可单独或与其他合理可用的信息结合起来合理使用的信息，用于识别或联系某个人）。个人信息包括但不限于用户数据、元数据和用户内容。其中可能包括姓名、电话号码、电子邮件地址、密码、评估数据或使用信息等。任何与个人信息相关的信息都将被视为个人信息。
            </p>
            <p>
                2. 通过使用或访问服务，您同意并接受本隐私政策中所示的数据措施和条款。此处未定义的首字母术语具有条款和条件（“条款和条件”）中规定的含义。

                我们可能会更新隐私政策，以便反映我们在信息方面更改的措施。如果在实质性的变化后更新了隐私政策，我们会在变化后至少7天内在网站上张贴通知，说明我们修改了本隐私政策，并在隐私政策的底部注明这些条款的最后修订日期。本隐私政策的任何修订将在该7天期限结束时或您在任何此类变更后首次访问或使用本服务时生效，以较早者为准。
            </p>
            <h4>我们收集的信息</h4>
            <p>
                3. 当您使用我们的服务时，您向我们提供并同意我们收集和使用两类个人信息。(i) 您通过服务提交的信息和(ii) 我们在服务期间与您互动时收集的与服务使用有关的信息。
            </p>
            <p>
                4.
                当您进入本网站时，我们会收集您的浏览器类型和IP地址（一个在互联网上识别您计算机的唯一地址）。当您使用我们的移动应用程序时，我们会收集您的设备类型、设备ID和IP地址。此外，我们使用“cookies”从您的浏览器存储某些信息。Cookie是存储在用户计算机上的一些数据，与用户的信息相关。我们使用会话ID
                cookie来确认用户已经登录。如果您不希望通过使用cookies收集信息，在大多数浏览器中都有一个简单的程序，允许您拒绝或接受cookies功能；但是，请注意，cookies可能是我们为您提供网站上的某些可用功能时必要的部分（例如，量身定制的信息传送）。
            </p>
            <p>
                5. 您可以在注册过程中向我们提供您的姓名、电话号码和电子邮件地址，以及注册过程中可能要求您提供的其他信息。
            </p>
            <p>
                6. 当您使用服务时，您可以在您的个人资料中提交信息和内容，通过参与服务的教学活动产生数据（“活动数据”），或向其他用户发送信息和以其他方式传送信息。我们存储这些信息，以便我们能够向您提供服务以及个性化的功能。
            </p>
            <p>
                7.
                当您使用我们的服务时，您必须向我们授予您计算机的网络摄像头和麦克风的访问权。我们将通过摄像头和麦克风来记录您的整个课程情况（“课程回放”）。我们存储该录音是出于培训目的，并保证质量，为学生提供视频回放功能，以便其进行复习。
            </p>
            <h4>公司所获信息的使用</h4>
            <p>
                8. 我们可能会使用您的联系信息，向您（但非您的孩子）发送本公司提供的、我们认为您可能觉得有价值的新服务通知。本公司也可能通过服务的一般操作不时向您发送与服务相关的公告。您通常可以选择不接收此类邮件。
            </p>
            <p>
                9. 您可以在注册服务时创建一个配置文件。如果您使用实时聊天功能注册，我们的团队可能会帮助您创建您的初始配置文件。您可以随时在我们的平台上编辑您的个人资料信息，或向我们的学习顾问寻求帮助。
            </p>
            <p>
                10. 我公司可以使用或分享通过服务收集的总数据或匿名数据，包括活动数据，将其用于了解或改进服务等目的。
            </p>
            <h4>与第三方分享您的个人信息</h4>
            <p>
                11. 我公司不会向任何第三方出售，或在不违反以下条款的情况下，向任何第三方披露您的个人信息。
            </p>
            <p>
                12.
                我们将与第三方分享您的个人信息，以便向我们或您提供与服务有关的服务，但须遵守保密义务，限制他们使用和披露这些信息。例如，我们可能向服务供应商提供您的个人信息，以帮助我们开展业务活动，发送我们的电子邮件，处理付款，或提供客户服务。
            </p>
            <p>
                13.
                我们可以合理地确定，我们需要根据法律规定，如传票或法院命令，或遵守适用的法律来披露个人信息。此外，当我们认为有必要遵守适用法律、执行我们的服务条款和条件、保护我们的利益或财产、防止通过服务或使用我们公司名称进行欺诈或其他有害、不道德或非法活动时，我们可能会共享账户或其他个人信息。这可能包括与其他公司、律师、代理人或政府机构共享个人信息。这种共享可能在未经您同意和不通知您的情况下进行。
            </p>
            <p>
                14.
                我们可能与我们的子公司、母公司、子公司和投资者共享个人信息。如果本公司所有或大部分业务、或本公司拥有的与服务相关的个别业务单位或资产的所有权发生变更，或破产或发生其他变更，即使该等交易尚未完成，您的个人信息也可能在该等交易期间（如尽职调查期间）被转移给新的所有者或被共享。在任何此类信息转移中，您的个人信息将继续受本隐私政策的约束，除非根据本条款进行更改。
            </p>
            <h4>链接</h4>
            <p>
                16. 服务可能包含其他网站的链接。我们对其他网站的隐私措施概不责任。我们鼓励用户阅读其他收集个人信息的网站的隐私声明。本隐私政策仅适用于本公司通过服务收集的信息
            </p>
            <h4>信息安全</h4>
            <p>
                17. 本公司已经实施了管理和技术保障措施，旨在确保您信息、访问凭证和结果的保密性、完整性和可用性。然而，互联网上的传输方法或电子存储方法并非完全安全。因此，虽然我们尽力保护您的个人信息，但我们不能保证其绝对安全。

                如果本公司发现有影响您个人信息的数据泄露，我们将按照所有适用法律通知您。例如，我们可能在本网站或服务的其他地方张贴通知，并可能将电子邮件发送至您提供给我们的电子邮件地址。根据您的所在地，您在法律上可能有权利收到安全漏洞的书面通知。
            </p>
            <h4>
                同意
            </h4>
            <p>
                18.
                本隐私政策适用于您可能提供给我们的所有个人信息以及我们持有的您的个人信息。通过向我们提供您的个人信息，或访问、使用或查看适用的网站或其任何服务、功能或内容（包括传输、缓存或存储任何此类个人信息），您应被视为已同意本隐私政策中的每项及所有条款、条件和通知。
            </p>
            <h4>隐私政策更新</h4>
            <p>
                19. 本公司保留随时修改和变更本隐私政策条款的权利，并将在本公司网站上发布更新的隐私政策（如有），该变更将在本公司发出该变更通知的七（7）天后生效。在生效日期之后使用我们的服务，即表示您同意并认可修订后的隐私政策。
            </p>
            <h4>儿童隐私</h4>
            <p>
                20.
                未满13岁的儿童不得使用我们的服务，除非事先得到父母或监护人的同意。我们不会有意收集13岁以下儿童的任何可识别个人信息。如果我们了解到，在未经父母事先同意的情况下，我们的服务收集了儿童的个人信息，那么我们将采取适当的措施，在发现这些信息时将其删除，且不会使用这些信息或与第三方分享。如果您是家长或监护人，并发现您未满13岁（或适用法律规定的更高年龄）的孩子未经您同意在我们的服务中注册了账户，请联系我们，我们可以从系统中删除该孩子的个人信息。
            </p>
            <h4>联系我们</h4>
            <p>21. 如欲了解本公司隐私政策的更多详情，访问您的信息，或就我们的隐私措施提出问题或进行投诉，请联系我们：support@sino-bus.com。</p>
            <p>最后修订于2023年09月01日。</p>
            <p>接受本文件即表示您同意隐私政策。</p>
        </div>
        <div class="content-text">
            <h3>Privacy Policy</h3>
            <p>We protect your personal information</p>
            <p>overview</p>
            <p>
                1. Sino-Bus respects the privacy of your personal information, so we have formulated this privacy policy
                (our
                "Privacy Policy") to explain how we collect, use, share and protect your personal information (which can be
                used
                separately or Information that is reasonably used in combination with other reasonably available information
                to
                identify or contact a person). Personal Information includes, but is not limited to, User Data, Metadata,
                and User
                Content. This may include names, phone numbers, email addresses, passwords, evaluation data or usage
                information,
                etc. Any information related to personal information will be considered personal information.
            </p>
            <p>

                2. By using or accessing the Services, you agree to and accept the data practices and terms indicated in
                this
                Privacy Policy. Capitalized terms not defined herein have the meanings set forth in the Terms and Conditions
                (“Terms
                and Conditions”).
                We may update the Privacy Policy to reflect our changed information practices. If the Privacy Policy is
                updated
                after a material change, we will post a notice on the Site at least 7 days after the change stating that we
                have
                revised this Privacy Policy and noting the date these terms were last revised at the bottom of the Privacy
                Policy.
                Any revisions to this Privacy Policy will be effective at the end of that 7-day period or when you first
                access or
                use the Service after any such change, whichever is earlier.
            </p>
            <h4> Information We Collect</h4>
            <p>
                3. When you use our services, you provide us with and agree to our collection and use of two types of
                personal
                information. (i) information you submit through the Services and (ii) information we collect in connection
                with use
                of the Services when we interact with you during the Services.
            </p>
            <p>
                4. When you enter this website, we will collect your browser type and IP address (a unique address that
                identifies
                your computer on the Internet). When you use our mobile applications, we collect your device type, device ID
                and IP
                address. In addition, we use "cookies" to store certain information from your browser. A cookie is some data
                stored
                on the user's computer, which is related to the user's information. We use session ID cookies to confirm
                that a user
                is logged in. If you do not want information collected through the use of cookies, there is a simple
                procedure in
                most browsers that allows you to refuse or accept the functionality of cookies; however, please note that
                cookies
                may be used by us to provide you with certain features available on the website when necessary (for example,
                tailor-made messaging).
            </p>
            <p>
                5. You may provide us with your name, phone number and email address during the registration process, as
                well as
                other information that may be requested from you during the registration process.
            </p>
            <p>
                6. When you use the Services, you may submit information and content in your profile, generate data by
                participating
                in educational activities on the Services (“Activity Data”), or send and otherwise transmit information to
                other
                users. We store this information so that we can provide you with services and personalized features. </p>
            <p>
                7. When you use our services, you must grant us access to your computer's webcam and microphone. We will
                record your
                entire session ("Session Replay") via a camera and microphone. We store this recording for training
                purposes, to
                maintain quality, and to provide video playback for students to review. </p>
            <h4>Use of Information Obtained by the Company</h4>
            <p>
                8. We may use your contact information to send you (but not your child) notifications about new services
                offered by
                our company that we think you may find valuable. The company may also send you service-related announcements
                from
                time to time through the general operation of the service. You can usually choose not to receive such
                emails.
            </p>
            <p>
                9. You can create a profile when you sign up for the service. If you sign up using the live chat feature,
                our team
                may help you create your initial profile. You can edit your profile information on our platform at any time,
                or seek
                help from our study advisors.
            </p>
            <p>
                10. Our company may use or share aggregate or anonymous data collected through the service, including
                activity data,
                for purposes such as understanding or improving the service. </p>
            <h4>Sharing your personal information with third parties</h4>
            <p>
                11. Our company will not sell to any third party, or disclose your personal information to any third party
                without
                violating the following terms.
            </p>
            <p>
                12. We will share your personal information with third parties in order to provide us or you with services
                related
                to the Services, subject to confidentiality obligations that restrict their use and disclosure of such
                information.
                For example, we may provide your personal information to service providers to help us conduct our business
                activities, send our emails, process payments, or provide customer service.
            </p>
            <p>
                13. We can reasonably determine that we are required to disclose personal information as required by law,
                such as a
                subpoena or court order, or to comply with applicable law. In addition, when we believe it is necessary to
                comply
                with applicable law, enforce our terms and conditions of service, protect our interests or property, prevent
                fraud
                or other harmful, unethical or illegal activities through the service or use of our company name, we may
                Share
                account or other personal information. This may include sharing personal information with other companies,
                attorneys, agents or government agencies. This sharing may be done without your consent and without notice
                to you.
            </p>
            <p>
                14. We may share personal information with our subsidiaries, parent company, subsidiaries and investors. If
                there is
                a change in ownership of all or a substantial portion of our business, or of individual business units or
                assets
                owned by us in connection with the Services, or in the event of bankruptcy or other change, even if such
                transactions have not yet been consummated, your personal information may be stored in the transfer to a new
                owner
                or be shared during transactions such as due diligence. In any such transfer of information, your personal
                information will continue to be governed by this Privacy Policy unless changed in accordance with these
                Terms.
            </p>
            <h4> update or delete your personal information</h4>
            <p>15. You may request that we delete personal information collected through use of this website. We will use
                commercially reasonable efforts to comply with your request, although we cannot guarantee successful removal
                of
                information in all cases. We may retain aggregate and/or anonymous data collected through the Services,
                including
                activity data, which may be used by the Company for training, improvement, promotion, research and other
                lawful purposes.
            </p>
            <h4> Link</h4>
            <p>
                16. The Service may contain links to other websites. We are not responsible for the privacy practices of
                other
                websites. We encourage users to read the privacy statements of other websites that collect personal
                information.
                This Privacy Policy applies only to information collected by the Company through the Services </p>
            <h4>information security</h4>
            <p>
                17. The Company has implemented administrative and technical safeguards designed to ensure the
                confidentiality,
                integrity and availability of your information, access credentials and results. However, no method of
                transmission
                over the Internet or method of electronic storage is completely secure. Therefore, while we do our best to
                protect
                your personal information, we cannot guarantee its absolute security.
                If the Company becomes aware of a data breach affecting your personal information, we will notify you in
                accordance
                with all applicable laws. For example, we may post notices elsewhere on the Site or Services, and we may
                send emails
                to the email address you have provided us. Depending on where you are located, you may have a legal right to
                receive
                written notification of a security breach.
            </p>
            <h4>
                agree
            </h4>
            <p>
                18. This Privacy Policy applies to all personal information that you may provide to us and to any personal
                information we hold about you. By providing us with your personal information, or by accessing, using or
                viewing the
                applicable website or any of its services, features or content (including transmitting, caching or storing
                any such
                personal information), you shall be deemed to have agreed to this Privacy Policy Each and all terms,
                conditions and
                notices in .
            </p>
            <h4>Privacy Policy Updates</h4>
            <p>
                19. The company reserves the right to modify and change the terms of this privacy policy at any time, and
                will
                publish the updated privacy policy (if any) on the company's website, and the change will take effect seven
                (7) days
                after the company issues the change notice . By using our Services after the Effective Date, you agree to
                and
                acknowledge the revised Privacy Policy. </p>
            <h4>Children's Privacy</h4>
            <p>
                20. Children under the age of 13 are not permitted to use our services unless prior consent has been
                obtained from
                their parents or guardians. We do not knowingly collect any personally identifiable information from
                children under
                the age of 13. If we learn that our Services have collected personal information from a child without prior
                parental
                consent, we will take appropriate steps to delete the information when found and will not use the
                information or
                communicate with Shared by third parties. If you are a parent or guardian and become aware that a child
                under the
                age of 13 (or a higher age as required by applicable law) has registered for an account on our Services
                without your
                consent, please contact us and we can remove that account from our systems. Personal Information of
                Children.
            </p>
            <h4>Contact us</h4>
            <p> 21. For more details about our company's privacy policy, to access your information, or to ask questions or
                make
                complaints about our privacy practices, please contact us: support@sino-bus.com.</p>
            <p>Last revised on September 01, 2023.</p>
            <p>By accepting this document you agree to the Privacy Policy.</p>
        </div>
    </div>
}
export default Privacy