import "./style.scss"
import {
    DownOutlined, MenuOutlined
} from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useSelector, } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react";
import { noop } from "antd/es/_util/warning";
const HeaderCom = (props) => {
    const count = useSelector(state => state.counter.value);
    console.log("count", count);
    // 点击背景色切换
    // 是否展示d导航条
    const [isNav, setIsNav] = useState(false);
    // 是否展示小标题
    const [isSmallNav, setIsSmallNav] = useState(true);
    const nav = useNavigate();
    const items = [{
        key: '1',
        label: (
            <div className="homeNav nav" onClick={(e) => switchPages(e, "/hongkong",)}>中文</div>
        ),
    },
    {
        key: '2',
        label: (
            <div className="hkNav nav" onClick={(e) => switchPages(e, "/hongkongmaths",)} >数学</div>
        ),
    },
    {
        key: '3',
        label: (
            <div className="hkNav nav" onClick={(e) => switchPages(e, "/hongkong-English",)} >英文</div>
        ),
    },]

    const switchPage = (url, num) => {
        if (num === 0) {
            nav("/")
        } else if (num === 1) {
            nav("/hongkong")
        } else if (num === 2) {
            nav("/hongkong")
        } else if (num === 3) {
            nav("/hongkongmaths")
        } else if (num === 4) {
            nav("/hongkong-English")
        } else if (num === 5) {
            nav("/singapore")
        } else if (num === 6) {
            nav("/aboutus")
        }
        // nav(url)
    };
    const switchPages = (e, url) => {
        nav(url)
    };
    let navList = ["首页", "香港课程", "中文", "数学", "英语", "新加坡", "联系我们"];

    // 展示
    const showNav = () => {
        setIsNav(!isNav);
        if (count === 1 || count === 3 || count === 4) {
            setIsSmallNav(false)
        } else {
            setIsSmallNav(true)
        }
    };
    // 点击手机端导航
    const handelNav = (index) => {
        console.log("000000000000000000000", index);
        switchPage("", index);
        setIsNav(false);
    };

    return (
        <div className="header">
            <button style={{ width: "280px" }} onClick={() => { nav('/') }}><img src="../assets/logo.png" alt=""></img></button>
            <div className="navText">
                <div className={count === 0 ? 'nav' : ""} onClick={(e) => switchPage("/homepage", 0)}><button style={{ padding: '0px', background: 'none' }}>首页</button> </div>
                <Dropdown menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: count === 3 ? ["2"] : ["1"],
                }} placement="bottom" overlayStyle={{ top: '80px', width: '100px', textAlign: "center", }}>
                    <div className={count === 1 || count === 3 || count === 4 ? 'nav' : ""} onClick={(e) => switchPage("/hongkong", 1)}>
                        <button style={{ padding: '0px', background: 'none' }}>香港课程</button> <DownOutlined />
                    </div>

                </Dropdown>
                <div className={count === 5 ? 'nav' : ""} onClick={(e) => switchPage("/singapore", 5)}><button style={{ padding: '0px', background: 'none' }}>新加坡</button></div>
                <div className={count === 6 ? 'nav' : ""} onClick={(e) => switchPage("/aboutus", 6)}><button style={{ padding: '0px', background: 'none' }}>联系我们</button></div>
            </div>
            <div className="mbMenu">
                <MenuOutlined onClick={() => { showNav() }} />
                {
                    isNav ? <ul className="navList">
                        {
                            navList.map((item, index) => {
                                return (
                                    <li key={index} className={[isSmallNav && index === 2 || isSmallNav && index === 3 || isSmallNav && index === 4 ? "hide" : "", index === 2 || index === 3 || index === 4 ? "show" : "", count === 3 && index === 1 || count == 1 && index === 2 || count == 4 && index === 1 || count == index ? 'yellow' : ""].join(' ')} onClick={() => { handelNav(index) }}>
                                        {item} {index === 1 ? <DownOutlined onClick={(e) => { e.stopPropagation(); setIsSmallNav(!isSmallNav) }} /> : ""}</li>
                                )
                            })
                        }
                    </ul> : ""
                }
            </div>

        </div >
    )
}
export default HeaderCom;