import { useEffect } from 'react'
import './transitStyls.scss'
const TransitPage = () => {
    let isTrue = false;
    useEffect(() => {
        if (isTrue === false) {
            isTrue = true;
            const scriptDiv1 = document.createElement('script');
            scriptDiv1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-438384628';
            const scriptDiv2 = document.createElement('script');
            scriptDiv2.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MH29QND');`;
            const scriptDiv3 = document.createElement('script');
            scriptDiv3.innerHTML = ` window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
             gtag('js', new Date());
            gtag('config', 'AW-438384628');`;
            document.head.appendChild(scriptDiv3);
            document.head.appendChild(scriptDiv2);
            document.head.appendChild(scriptDiv1);
            const noscriptDiv = document.createElement('noscript');
            noscriptDiv.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MH29QND"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
            document.body.appendChild(noscriptDiv)
            document.title = '香港中文補習網課，呈分試，幼小銜接';
        }
    }, []);

    const goUrl = () => {
        window.location.href = "https://wa.me/8617788052878?text=%E4%B8%AD%E6%96%87%E5%92%A8%E8%A9%A2";
    }
    return (
        <div className='transitPage'>
            <div className="banner">
                <img className="banner-img" src="../assets/hkimg/banner-pc.jpg" alt="" />
                <img className="banner-img1" src="../assets/hkimg/banner.jpg" alt="" />
            </div>
            <div className='chatIcon' onClick={() => { goUrl() }}>
                點撃獲取免費試堂
                <div className='arrow'>{'>'}</div>
            </div>
            <button className='leave-btn' onClick={() => { window.close() }}>離開</button>
        </div>
    )
}
export default TransitPage